<template>
    <div class="m-select-wrap f14" :style="`width: ${width}px;`">
        <input
            :class="['u-select-input', { 'disabled': disabled }]"
            :style="`width: ${width}px;`"
            type="text"
            :disabled="disabled"
            :placeholder="placeholder"
            readonly
            :title="selectName"
            @click="openSelect"
            @blur="onBlur"
            v-model="selectName" />
        <div :class="['triangle-down', { 'rotate': rotate, 'disabled': disabled }]" @click="openSelect"></div>
        <div :class="['m-options-panel', showOptions ? 'show': 'hidden']" :style="`height: ${selectData.length * 40}px; max-height: 320px; width: ${width}px;`">
            <p class="u-option" :title="mode==='region' ? item.dictVal : item.name" @mousedown="getValue(mode==='region' ? item.dictVal : item.name, mode==='region' ? item.dictKey : item.value)" v-for="(item, index) in selectData" :key="index">
                {{ mode==='region' ? item.dictVal : item.name }}
            </p>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Select',
    props: {
        selectData: {
            type: Array,
            default: () => {
                return []
            }
        },
        selValue: { // 将该prop值作为selV的初始值
            default: undefined
        },
        placeholder: {
            type: String,
            default: '请选择'
        },
        width: { // 下拉框宽度
            type: Number,
            default: 290
        },
        disabled: {
            type: Boolean,
            default: false
        },
        mode: {
            type: String,
            default: 'default'
        }
    },
    computed: {
        selectName () {
            if (this.mode === 'region') {
                for (const item of this.selectData) {
                    if (item.dictKey === this.selectValue) {
                        return item.dictVal
                    }
                }
            } else {
                for (const item of this.selectData) {
                    if (item.value === this.selectValue) {
                        return item.name
                    }
                }
            }
            return ''
        }
    },
    data () {
        return {
            selectValue: this.selValue,
            rotate: false,
            showOptions: false
        }
    },
    methods: {
        openSelect () {
            this.showOptions = !this.showOptions
            this.rotate = !this.rotate
        },
        getValue (name, value) {
            this.selectValue = value
            // 选择之后关闭
            this.showOptions = !this.showOptions
            this.rotate = !this.rotate
            this.$emit('getValue', name, value)
        },
        onBlur () {
            this.showOptions = false
            this.rotate = false
        }
    }
}
</script>
<style lang="less" scoped>
.m-select-wrap {
    box-sizing: border-box;
    font-size: 14px;
    display: inline-block;
    width: 290px;
    height: 32px;
    position: relative;
    color: #444444;
    border-radius: 30px;
    .u-select-input {
        font-size: 15px;
        font-family: 'REEJI-PinboGB-Flash';
        color: #000;
        box-sizing: border-box;
        text-align: center;
        width: 256px;
        height: 100%;
        border-radius: 29px;
        border: none;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    /deep/.u-select-input::-webkit-input-placeholder{
        color: #B2B2B2;
        font-size: 15px;
        font-family: 'REEJI-PinboGB-Flash';
    }
    /deep/.u-select-input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color: #B2B2B2;
        font-size: 15px;
        font-family: 'REEJI-PinboGB-Flash';
    }
    /deep/.u-select-input::-moz-placeholder { /* Mozilla Firefox 19+ */
        color: #B2B2B2;
        font-size: 15px;
        font-family: 'REEJI-PinboGB-Flash';
    }
    /deep/.u-select-input:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #B2B2B2;
        font-size: 15px;
        font-family: 'REEJI-PinboGB-Flash';
    }
    /deep/.u-select-input::-ms-input-placeholder { /* Microsoft Edge */
        color: #B2B2B2;
        font-size: 15px;
        font-family: 'REEJI-PinboGB-Flash';
    }
    .triangle-down { // 下三角
        width: 15px;
        height: 15px;
        font-size: 0;
        background: url(../../assets/bracelet_img/44.png);
        background-size: 100% 100%;
        position: absolute;
        top: 9px;
        right: 10px;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
    }
    .rotate {
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
    }
    .disabled {
        cursor: default;
        pointer-events: none;
    }
    .m-options-panel {
        position: absolute;
        overflow-y: auto;
        background: #FFFFFF;
        width: 288px;
        border-radius: 10px;
        top: 37px;
        left: 0;
        color: #000;
        z-index: 99;
        .u-option {
            font-size: 12px;
            font-family: 'REEJI-PinboGB-Flash';
            padding: 5px 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap; // 溢出显示省略号
            cursor: pointer;
        }
        .u-option:hover {
            background-color: rgba(43, 43, 43, 0.5);
            color: #a7f264;
        }
    }
    .m-options-panel::-webkit-scrollbar{
        display: none;
    }
    .show {
        display: block;
    }
    .hidden {
        display: none;
    }
}
</style>