<template>
    <div class="ranking">
        <watch-head :title="$t('排行')" :img="require('@/assets/bracelet_img/60.png')" />

        <div class="swiper-box" v-show="bannerList.length > 0">
            <swiper class="swiper" :options="swiperOption">
                <swiper-slide v-for="(item, index) in bannerList" :key="index">
                    <img :src="item.image" class="img" alt="">
                </swiper-slide>
                <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
        </div>

        <div class="switch">
            <div class="item" v-for="(item) in tabList" :key="item.key" :class="{'act': item.key == selLabel, 'KenyanCoffeeRg': item.key == 'solarix' || item.key == 'ionz', 'vn-bold-italic': $i18n.locale == 'vn'}" @click="choice(item.key)">{{ item.name }}</div>
        </div>

        <div class="main">
            <Select
                class="select_phone"
                :selectData="selectData"
                :selValue="selType"
                :width="153"
                :placeholder="$t('请选择类型')"
                @getValue="selectType" />
            <!-- <van-field class="time" v-model="time" readonly label-width="0" input-align="center" :placeholder="$t('时间')">
                <template #right-icon>
                    <img @click="dateShow = true" class="xia" src="@/assets/bracelet_img/44.png" alt="">
                </template>
            </van-field> -->
            <div class="topThree" :class="{'vn-bold-italic': $i18n.locale == 'vn'}">- {{$t('前三名玩家')}} -</div>
            <div class="topThreeList">
                <div class="item" v-for="(item, index) in listOne" :key="index">
                    <div class="box">
                        <div class="left">
                            <div class="avatar">
                                <van-image
                                    class="image"
                                    lazy-load
                                    round
                                    :src="item.avatar"
                                />
                                <div class="grade">{{ item.level_no }}</div>
                            </div>
                            <div class="info">
                                <div class="name" :class="{'vn-italic': $i18n.locale == 'vn'}">{{ item.nickname ? item.nickname : $t('暂无昵称') }}</div>
                                <div v-show="selLabel == 'step_number'" class="total" :class="{'vn-italic': $i18n.locale == 'vn'}">{{$t('总共')}} : {{ item.step_number ? item.step_number : 0 }}</div>
                                <div v-show="selLabel == 'calorie'" class="total" :class="{'vn-italic': $i18n.locale == 'vn'}">{{$t('总共')}} : {{ item.calorie ? item.calorie : 0 }}</div>
                                <div v-show="selLabel == 'social_score'" class="total" :class="{'vn-italic': $i18n.locale == 'vn'}">{{$t('总共')}} : {{ item.social_score ? item.social_score : 0 }}</div>
                                <div v-show="selLabel == 'earnings'" class="total" :class="{'vn-italic': $i18n.locale == 'vn'}">{{$t('总共')}} : {{ item.earnings ? item.earnings : 0 }}</div>
                                <div class="info_btm" v-show="userInfo.id != item.user_id">
                                    <div class="chat" @click="goTo('/message', item.user_id)">
                                        <img src="@/assets/bracelet_img/59.png" alt="">
                                    </div>
                                    <div class="friend" v-if="item.is_friend == 0" @click="addTo(item.user_id, index)">
                                        <img src="@/assets/bracelet_img/58.png" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="right">
                            <span>{{ index + 1 }}</span>
                            <span v-show="index == 0" class="st">st</span>
                            <span v-show="index == 1" class="st">nd</span>
                            <span v-show="index == 2" class="st">rd</span>
                        </div>
                    </div>
                    <div class="bg" :class="{'one': index == 0, 'two': index == 1, 'three': index == 2}"></div>
                </div>
                <div v-show="listOne.length == 0" class="card"><van-empty image="error" image-size="100" :class="{'vn-italic': $i18n.locale == 'vn'}" :description="$t('暂无排名')" /></div>
            </div>
            <div class="player" :class="{'vn-bold-italic': $i18n.locale == 'vn'}">- {{$t('玩家排行')}} -</div>
            <div class="playerList">
                <div class="item" v-for="(item, index) in listTwo" :key="index">
                    <div class="box">
                        <div class="left">{{ index + 4 }}</div>
                        <div class="right">
                            <div class="avatar">
                                <van-image
                                    class="image"
                                    lazy-load
                                    round
                                    :src="item.avatar"
                                />
                            </div>
                            <div class="info">
                                <div class="name" :class="{'vn-italic': $i18n.locale == 'vn'}">{{ item.nickname ? item.nickname : $t('暂无昵称') }}</div>
                                <div v-show="selLabel == 'step_number'" class="total" :class="{'vn-italic': $i18n.locale == 'vn'}">{{$t('总共')}} : {{ item.step_number ? item.step_number : 0 }}</div>
                                <div v-show="selLabel == 'calorie'" class="total" :class="{'vn-italic': $i18n.locale == 'vn'}">{{$t('总共')}} : {{ item.calorie ? item.calorie : 0 }}</div>
                                <div v-show="selLabel == 'social_score'" class="total" :class="{'vn-italic': $i18n.locale == 'vn'}">{{$t('总共')}} : {{ item.social_score ? item.social_score : 0 }}</div>
                                <div v-show="selLabel == 'earnings'" class="total" :class="{'vn-italic': $i18n.locale == 'vn'}">{{$t('总共')}} : {{ item.earnings ? item.earnings : 0 }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="bg"></div>
                </div>
                <div v-show="listTwo.length == 0" class="card"><van-empty image="error" image-size="100" :class="{'vn-italic': $i18n.locale == 'vn'}" :description="$t('暂无排名')" /></div>
            </div>
        </div>
        <watch-foot />
        <!-- 月份弹出层 -->
        <van-popup v-model="dateShow" position="bottom">
            <van-datetime-picker
                :class="{'vn-italic': $i18n.locale == 'vn'}"
                v-model="currentDate"
                @confirm="confirmDate"
                @cancel="cancelDate"
                type="date"
                :confirm-button-text="$t('确定')"
                :cancel-button-text="$t('取消')"
                :title="$t('选择年月日')"
            />
        </van-popup>
    </div>
</template>

<script>
    import watchHead from '@/components/watchHead/index.vue'
    import watchFoot from '@/components/watchFoot/index.vue'
    import Select from '@/components/select/index.vue'
    import { getRankingList, addFriendsRequest, getBannerList } from '@/api/api'
    import { mapState } from 'vuex'
    import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
    import 'swiper/css/swiper.css'
	export default {
        components: {
            watchHead,
            watchFoot,
            Select,
            Swiper,
            SwiperSlide
        },
		data() {
			return {
                bannerList: [],
                addLoading: false,
                userInfo:{
                    id: null,
                },
                selectData: [
                    {
                        value: 1,
                        name: this.$t('日榜')
                    }, 
                    {
                        value: 2,
                        name: this.$t('月榜')
                    }, 
                    {
                        value: 3,
                        name: this.$t('总榜')
                    },
                ],
                selType: 1,
                selLabel: 'step_number',
                swiperOption: {
                    effect: 'coverflow',
                    grabCursor: true,
                    centeredSlides: true,
                    slidesPerView: 'auto',
                    // loop: true,
                    loopedSlides: 2,
                    coverflowEffect: {
                        rotate: 0,
                        stretch: '80%',
                        depth: 312,
                        modifier: 1,
                        slideShadows: false
                    },
                    pagination: {
                        el: '.swiper-pagination'
                    },
                },
                tabType: 1,
                tabList: [
                    // { key: 1, name: this.$t('运动') },
                    // { key: 2, name: this.$t('社交') },
                    // { key: 3, name: 'SOLARIX' },
                    // { key: 4, name: 'IONZ' }
                    { key: 'step_number', name: this.$t('步数') },
                    { key: 'calorie', name: this.$t('卡路里') },
                    { key: 'social_score', name: this.$t('评分(人气)') },
                    { key: 'earnings', name: this.$t('收益') }
                ],
                time: '',
                timer: null,
                listOne: [],
                listTwo: [],
                listLoading: false,
                dateShow: false,
                currentDate: '',
			}
		},
        computed: {
            ...mapState([
                'web3',
            ]),
        },
		created(){ this.waitForGlobal(); },
		methods: {
            getBannerList(){
                getBannerList().then(res => {
                    if(res.code == 0){
                        this.bannerList = res.data
                    }
                })
            },
            addTo (id, index) {
                if (!this.addLoading) {
                    this.addLoading = true
                    addFriendsRequest({ id }).then(res => {
                        if (res.code == 0) {
                            this.listOne[index].is_friend = 1
                        }
                        this.addLoading = false
                        this.$toast(res.msg);
                    })
                }
            },
            selectType(name, type){
                this.selType = type
                this.listOne = []
                this.listTwo = []
                this.getList();
            },
            getList(){
                if(!this.listLoading){
                    this.listLoading = true
                    getRankingList({
                        type: this.selType,
                        label: this.selLabel,
                    }).then(res => {
                        if(res.code == 0){
                            let listOne = [],listTwo = [];
                            //将数组拆分为两个
                            for (let index = 0; index < res.data.length; index++) {
                                const element = res.data[index];
                                if(index <= 2){
                                    listOne.push(element);
                                }else{
                                    listTwo.push(element);
                                }
                            }
                            this.listOne = listOne
                            this.listTwo = listTwo
                            this.listLoading = false
                        }
                    })
                }
            },
            goTo (url, fromUid) {
                this.$router.push({
                    path: url,
                    query: {
                        fromUid
                    }
                })
            },
			choice(type){
                // this.tabType = type
                this.selLabel = type
                this.listOne = []
                this.listTwo = []
                this.getList();
            },
            cancelDate(){
                this.dateShow = false
            },
            confirmDate(data){
                let d = new Date(data);
                let y = d.getFullYear();
                let m = d.getMonth() + 1;
                let s = d.getDate();
                if (m < 10) m = "0" + m;
                if (s < 10) s = "0" + s;
                let datetime = y + "-" + m + "-" + s
                console.log("选中了月份----", datetime);
                this.time = datetime
                this.listOne = []
                this.listTwo = []
                this.getList();
                this.dateShow = false
            },
            //链接钱包
            waitForGlobal: function () {
                window.clearTimeout(this.timer);
                // 1. 刷新页面时是否已经连接钱包登录
                if (localStorage.getItem('Token') && this.web3 && localStorage.getItem('defaultAddress') && localStorage.getItem('userInfo')) {
                    window.clearTimeout(this.timer);
                    this.listOne = []
                    this.listTwo = []
                    this.getList();
                    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
                    this.getBannerList();
                } else {
                    // 如果检测到没有连接钱包登录
                    this.timer = setTimeout(
                    this.waitForGlobal
                    ,100);
                }
            },
		}
	}
</script>

<style lang="less" scoped>
    .ranking{
        width: 100%;
		min-height: 100vh;
		padding-top: 75px;
		padding-bottom: 60px;
        background-image: url(~@/assets/bracelet_img/8.png);
        background-repeat: repeat-y;
		background-size: 100% 100%;
		color: #ffffff;

        .swiper-box{
            padding: 0 20px;
        }
        .swiper {
            width: 100%;
            margin-top: 20px;
            height: 150px;
            .swiper-slide {
                width: 90%;
                height: 130px;
                border-radius: 10px;
                .img{
                    width: 100%;
                    height: 130px;
                    border-radius: 10px;
                }
            }
            .swiper-pagination-bullets{
                bottom: 0;
            }
            .swiper-pagination {
                /deep/ .swiper-pagination-bullet.swiper-pagination-bullet-active {
                    background-color: #2b2b2b;
                }
            }
        }


        .switch{
            width: 100%;
            background-color: rgba(43, 43, 43, 0.5);
            margin-top: 15px;
            margin-bottom: 14px;
            padding: 7px 0;
            display: flex;
            justify-content: space-around;
            align-items: center;
            font-family: 'REEJI-PinboGB-Flash';
            font-size: 18px;
            color: #5c65cb;
            .act{
                color: #a7f264;
            }
            .KenyanCoffeeRg{
                font-family: 'KenyanCoffeeRg-BoldItalic';
            }
            .item{
                cursor: pointer;
            }
        }
        .main{
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0 26px;
            .van-field{
                width: 153px;
                background-color: #ffffff;
                border-radius: 15px;
                padding: 2px 7px;
                /deep/.van-field__right-icon{
                    display: flex;
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
                /deep/.xia{
                    width: 15px;
                    height: 15px;
                }
            }
            .time{
                /deep/.van-field__control{
                    font-family: 'REEJI-PinboGB-Flash';
                    font-size: 15px;
                    color: #2b2b2b;
                }
                /* WebKit, Blink, Edge */
                /deep/.van-field__control::-webkit-input-placeholder{
                    font-size: 15px;
                }
                /* Mozilla Firefox 4 to 18 */
                /deep/.van-field__control:-moz-placeholder{
                    font-size: 15px;
                }
                /* Mozilla Firefox 19+ */
                /deep/.van-field__control::-moz-placeholder{
                    font-size: 15px;
                }
                /* Internet Explorer 10-11 */
                /deep/.van-field__control:-ms-input-placeholder{
                    font-size: 15px;
                }
                /* Microsoft Edge */
                /deep/.van-field__control::-ms-input-placeholder{
                    font-size: 15px;
                }
            }
            .topThree{
                margin: 7px 0 7px;
                font-family: 'REEJI-PinboGB-Flash';
	            font-size: 18px;
            }
            .topThreeList{
                width: 100%;
                .item{
                    position: relative;
                    margin-bottom: 5px;
                    .box{
                        width: 100%;
                        padding: 8px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        background-blend-mode: normal, normal;
                        border-radius: 8px;
                        z-index: 1;
                        position: relative;
                        .left{
                            display: flex;
                            align-items: center;
                            width: 76%;
                            .avatar{
                                width: 56px;
                                min-width: 56px;
                                height: 56px;
                                border-radius: 50%;
                                background-color: #2b2b2b;
                                border: 3px solid #2b2b2b;
                                margin-right: 20px;
                                position: relative;
                                .image{
                                    width: 100%;
                                    height: 100%;
                                    border-radius: 50%;
                                }
                                .grade{
                                    position: absolute;
                                    bottom: -1px;
                                    right: -15px;
                                    width: 26px;
                                    height: 26px;
                                    background-color: #2b2b2b;
                                    font-family: 'AeroMaticsDisplayBoldItalic';
                                    font-size: 14px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    border-radius: 50%;
                                }
                            }
                            .info{
                                width: calc(100% - 76px);
                                .name{
                                    font-family: 'AeroMaticsDisplayBoldItalic';
                                    font-size: 22px;
                                    overflow: hidden;
                                    white-space: nowrap;
                                    text-overflow: ellipsis;
                                }
                                .total{
                                    font-family: 'TsangerYuYangT-W05';
                                    font-size: 11px;
                                    margin-bottom: 6px;
                                    margin-top: -2px;
                                }
                                .info_btm{
                                    display: flex;
                                    .chat{
                                        width: 23px;
                                        height: 23px;
                                        background-color: #a7f264;
                                        border-radius: 50%;
                                        margin-right: 6px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        img{
                                            width: 13px;
                                            height: 12px;
                                            
                                        }
                                    }
                                    .friend{
                                        width: 23px;
                                        height: 23px;
                                        background-color: #a7f264;
                                        border-radius: 50%;
                                        text-align: center;
                                        line-height: 23px;
                                        img{
                                            width: 11px;
                                            height: 12px;
                                        }
                                    }
                                }
                            }
                            
                        }
                        .right{
                            font-family: 'AeroMaticsDisplayBoldItalic';
                            font-size: 60px;
                            .st{
                                font-size: 43px;
                            }
                        }
                    }
                    .bg{
                        background-blend-mode: normal, normal;
                        border-radius: 8px;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        z-index: 0;
                        opacity: 0.75;
                    }
                    .one{
                        background-image: linear-gradient(237deg, rgba(250, 204, 34, 0.83) 0%, rgba(255, 248, 135, 0.83) 100%), linear-gradient(#ffffff, #ffffff);
                    }
                    .two{
                        background-image: linear-gradient(237deg, rgba(190, 190, 190, 0.83) 0%, rgba(238, 238, 238, 0.83) 50%, rgba(210, 210, 210, 0.83) 100%), linear-gradient(#ffffff, #ffffff);
                    }
                    .three{
                        background-image: linear-gradient(237deg, rgba(219, 161, 122, 0.83) 0%, rgba(200, 145, 129, 0.83) 100%), linear-gradient(#ffffff, #ffffff);
                    }
                }
                
            }
            .player{
                font-family: 'REEJI-PinboGB-Flash';
                font-size: 18px;
                margin: 6px 0 6px;
            }
            .playerList{
                width: 100%;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                .item{
                    position: relative;
                    margin-bottom: 5px;
                    width: 49%;
                    .box{
                        z-index: 1;
                        position: relative;
                        border-radius: 8px;
                        width: 100%;
                        margin-bottom: 5px;
                        padding: 6px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        .left{
                            font-family: 'AeroMaticsDisplayBoldItalic';
                            font-size: 24px;
                        }
                        .right{
                            display: flex;
                            align-items: center;
                            width: 83%;
                            .avatar{
                                width: 27px;
                                min-width: 27px;
                                height: 27px;
                                background-color: #2b2b2b;
                                border: 2px solid #2b2b2b;
                                margin-right: 5px;
                                border-radius: 50%;
                                .image{
                                    width: 100%;
                                    height: 100%;
                                    border-radius: 50%;
                                }
                            }
                            .info{
                                width: calc(100% - 32px);
                                .name{
                                    font-family: 'AeroMaticsDisplayBoldItalic';
                                    font-size: 18px;
                                    overflow: hidden;
                                    white-space: nowrap;
                                    text-overflow: ellipsis;
                                }
                                .total{
                                    font-family: 'TsangerYuYangT-W05';
                                    font-size: 10px;
                                }
                            }
                        }
                    }
                    .bg{
                        background-image: linear-gradient(237deg, rgba(118, 233, 214, 0.83) 0%,rgba(159, 174, 230, 0.83) 100%), linear-gradient(#ffffff, #ffffff);
                        background-blend-mode: normal, normal;
                        border-radius: 8px;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        z-index: 0;
                        opacity: 0.75;
                    }
                }
                .card{
                    width: 100%;
                }
            }
        }
        .van-picker{
            /deep/.van-picker__title{
                color: #000;
            }
        }
    }
    @media screen and (min-width: 480px){
        //在这里写非小屏幕设备的样式  -- 非手机
        /deep/.van-popup--bottom{
            width: 430px;
            left: calc(50% - 215px);
        }
    }
</style>